exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cottages-js": () => import("./../../../src/pages/cottages.js" /* webpackChunkName: "component---src-pages-cottages-js" */),
  "component---src-pages-directions-js": () => import("./../../../src/pages/directions.js" /* webpackChunkName: "component---src-pages-directions-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rates-js": () => import("./../../../src/pages/rates.js" /* webpackChunkName: "component---src-pages-rates-js" */),
  "component---src-templates-cottage-template-js": () => import("./../../../src/templates/cottage-template.js" /* webpackChunkName: "component---src-templates-cottage-template-js" */)
}

